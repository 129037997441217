import moment from 'moment';
import { useState } from 'react';
// eslint-disable-next-line no-use-before-define
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { useDispatch } from 'react-redux';

import { Importer, POADocumentType, RelatedResource, RelatedResourceStatus } from '@e-origin/shared';

import { NotificationModal } from '../../../components';
import {
  TableActionButtons,
  TableActions,
} from '../../../components/table-action-buttons/table-action-buttons.control';
import {
  deleteDocumentAction,
  downloadDocumentAction,
  generateDocumentAction,
  sendDocumentAction,
} from '../../../stores/importerSlice';
import * as DetailsStyle from '../importer-details.style';
import * as Style from './importer-details-poa.style';

export interface ImporterPOAProps {
  importer: Importer;
}

interface POADocument extends RelatedResource {
  type: POADocumentType;
}

// eslint-disable-next-line max-statements, complexity
export const ImporterPOA = ({ importer }: ImporterPOAProps) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<POADocument>(null);

  const toggleDeleteModal = async () => setShowDeleteModal(!showDeleteModal);
  const toggleSendModal = async () => setShowSendModal(!showSendModal);

  const generateDocument = (row: POADocument) => (ev) => {
    ev.preventDefault();
    dispatch(generateDocumentAction(importer._id, row.type));
  };

  const sendDocument = (row: POADocument) => (ev) => {
    ev.preventDefault();
    setSelectedRow(row);
    setShowSendModal(true);
  };

  const deleteDocument = (row: POADocument) => {
    setSelectedRow(row);
    setShowDeleteModal(true);
  };

  const downloadDocument = (row: POADocument) => {
    dispatch(downloadDocumentAction(importer._id, row.fileName));
  };

  const documents: POADocument[] = [
    ...(importer.poa?.relatedResources?.length
      ? importer.poa.relatedResources
      : [{ fileName: 'Create POA document', status: RelatedResourceStatus.EMPTY }]
    ).map((r) => ({ ...r, type: POADocumentType.POA })),
    ...(importer.vatGuarantee?.relatedResources?.length
      ? importer.vatGuarantee.relatedResources
      : [{ fileName: 'Create VAT Guarantee document', status: RelatedResourceStatus.EMPTY }]
    ).map((r) => ({ ...r, type: POADocumentType.VATGuarantee })),
  ];

  const columns: IDataTableColumn<POADocument>[] = [
    {
      name: 'Identification document',
      selector: 'type',
      width: '20%',
      cell: (row) => <span style={{ color: '#1870A3', fontWeight: '700' }}>{row.type}</span>,
    },
    {
      name: 'File name',
      cell: (row) => (row.issuedBy === 'SELLER' ? 'POA signed by importer' : 'Original generated POA'),
      width: '20%',
    },
    {
      name: 'Date',
      selector: 'issuedAt',
      width: '20%',
      cell: (row) => (row.issuedAt ? moment(row.issuedAt).format('DD-MM-YY @ HH:mm') : ''),
    },
    {
      name: 'Status',
      selector: 'status',
      width: '20%',
    },
    {
      name: 'Actions',
      right: true,
      // eslint-disable-next-line complexity
      cell: (row) => (
        <Style.ActionsContainer>
          {row.status === RelatedResourceStatus.EMPTY && (
            <Style.Button onClick={generateDocument(row)}>Generate {row.type}</Style.Button>
          )}
          {row.status === RelatedResourceStatus.NOT_SENT && (
            <Style.Button onClick={sendDocument(row)}>Send my signed {row.type} file</Style.Button>
          )}
          {row.status !== RelatedResourceStatus.EMPTY && (
            <TableActionButtons
              actions={[
                TableActions.DownloadFile,
                row.issuedBy === 'DECLARANT' && row.status !== RelatedResourceStatus.SENT
                  ? TableActions.Delete
                  : undefined,
              ]}
              onClick={(ev) => {
                switch (ev) {
                  case 'delete':
                    deleteDocument(row);
                    break;
                  case 'download':
                    downloadDocument(row);
                    break;
                  default:
                    break;
                }
              }}
            />
          )}
        </Style.ActionsContainer>
      ),
    },
  ];

  return (
    <div style={{ marginTop: 20 }}>
      <DetailsStyle.DocumentsContainer>
        <DataTable
          noHeader
          columns={columns}
          data={documents || documents}
          pagination
          customStyles={{
            tableWrapper: {
              style: {
                display: 'block',
              },
            },
          }}
        />
      </DetailsStyle.DocumentsContainer>
      {showDeleteModal && (
        <NotificationModal
          title="Delete document"
          confirmButtonText="Yes"
          show={showDeleteModal}
          onHide={toggleDeleteModal}
          onConfirm={() => dispatch(deleteDocumentAction(importer._id, selectedRow.fileName))}
        >
          <div>Do you really want to delete the document?</div>
        </NotificationModal>
      )}
      {showSendModal && (
        <NotificationModal
          title="Send document"
          confirmButtonText="Yes"
          show={showSendModal}
          onHide={toggleSendModal}
          onConfirm={() => dispatch(sendDocumentAction(importer._id, selectedRow.type))}
        >
          <div>Are you sure you want to send the document?</div>
        </NotificationModal>
      )}
    </div>
  );
};
